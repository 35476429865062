import React, { createContext, useContext, useRef } from "react";
import { driver } from "driver.js"; // ✅ FIXED IMPORT
import "driver.js/dist/driver.css";

const TourContext = createContext();

export const TourProvider = ({ children }) => {
  const driverRef = useRef(
    driver({ // ✅ Call the driver function instead of using `new Driver()`
      animate: true,
      overlayOpacity: 0.75,
      allowClose: false,
      overlayClickBehavior: "nextStep", // Click outside goes to next step
      showButtons: ["next", "previous", "close"],
      nextBtnText: "Next",
      prevBtnText: "Back",
      doneBtnText: "Finish",
    })
  );

  const startTour = () => {
    driverRef.current.setSteps([
      {
            element: "#logo",
            popover: {
                title: "ABM respiratory care",
                description: "Click here to return to the homepage.",
                side: "bottom",
            },
        },
        {
            element: "#date-picker",
            popover: {
              title: "Date Range Selector",
              description: "Use this to filter therapy data by date range.",
              side: "bottom",
            },
          },
        {
            element: "#therapy-list",
            popover: {
              title: "Therapy list",
              description: "Shows list of all the therapies present inside the List",
              side: "right",
            },
          },
        {
            element: "#add-graph",
            popover: {
              title: "Add Graph to report",
              description: "Click on Add graph to report to move the current view to the report",
              side: "left",
            },
          },
      {
        element: "#generate-report-btn",
        popover: {
          title: "Generate Report",
          description: "Hover here to generate and download a therapy report. Preview the graphs choosen",
          side: "left",
        },
      },
      
    ]);

    driverRef.current.drive(); // ✅ Start the tour
  };

  return (
    <TourContext.Provider value={{ startTour }}>
      {children}
    </TourContext.Provider>
  );
};

export const useTour = () => useContext(TourContext);
